import React, { useEffect, useState } from "react";
import resumeFile from "../assets/resume.pdf";
import { fetchAboutContent } from "../services/aboutService";

const AboutUs = ({ classicHeader, darkTheme }) => {
  const [aboutContent, setAboutContent] = useState([]); // State to store about content
  const [error, setError] = useState(null); // State for handling errors
  const [loading, setLoading] = useState(true); // State for loading status

  // Fetch content when the component mounts
  useEffect(() => {
    const getContent = async () => {
      try {
        setLoading(true); // Start loading
        const data = await fetchAboutContent(); // Fetch about content from API

        // Ensure that aboutContent is an array before setting state
        setAboutContent(Array.isArray(data) ? data : []);
      } catch (err) {
        console.error(err);
        setError("Failed to load about content."); // Set error if fetching fails
      } finally {
        setLoading(false); // Stop loading
      }
    };
    getContent();
  }, []);

  return (
    <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24 text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            About Me
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Know Me More
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>

        <div className="row gy-5">
          <div className="col-lg-7 col-xl-8 text-center text-lg-start">
            <h3 className={`text-7 fw-600 ${darkTheme ? "text-white" : ""}`}>
              Summary
            </h3>
            {loading && <p>Loading about content...</p>}
            {error && <p>{error}</p>}
            {!loading && !error && Array.isArray(aboutContent) && (
              aboutContent.map((item, index) => (
                <div key={index} className="mb-4">
                  {item.content.split("||").map((line, i) => (
                    <p key={i} className={darkTheme ? "text-white-50" : ""}>
                      {line}
                    </p>
                  ))}
                </div>
              ))
            )}
          </div>

          <div className="col-lg-5 col-xl-4" style={{ marginTop: "100px" }}>
            <div className="ps-lg-4">
              <ul
                className={
                  "list-style-2 " +
                  (darkTheme ? "list-style-light text-light" : "")
                }
              >
                <li>
                  <span className="fw-600 me-2">Name:</span>Ian Burke
                </li>
                <li>
                  <span className="fw-600 me-2">Email:</span>
                  <a href="mailto:ianburkework@yahoo.com">ianburkework@yahoo.com</a>
                </li>
                <li>
                  <span className="fw-600 me-2">Nationality:</span>Irish
                </li>
                <li>
                  <span className="fw-600 me-2">Languages:</span>English
                </li>
                <li>
                  <span className="fw-600 me-2">Age:</span>30
                </li>
                <li className="border-0">
                  <span className="fw-600 me-2">From:</span>Claregalway,
                  Co Galway, Ireland
                </li>
              </ul>
              <a
                href={resumeFile}
                download
                className="btn btn-primary rounded-pill"
              >
                Download CV
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
