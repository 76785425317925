import React, { useState, useEffect } from 'react';
import { fetchBlogPosts } from '../services/blogService';

// Blog component to display a list of blog posts
// Props:
// - classicHeader: Boolean, determines if classic header style is used to adjust padding
// - darkTheme: Boolean, applies dark styling to the component if true
const Blog = ({ classicHeader, darkTheme }) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Fetch blog entries from the backend when component mounts
  useEffect(() => {
    const getBlogPosts = async () => {
      try {
        setLoading(true); // Set loading to true while fetching
        const data = await fetchBlogPosts(); // Fetch data from backend
        setPosts(Array.isArray(data) ? data : []); // Ensure an array. Update posts state with fetched data
      } catch (err) {
        console.error(err);
        setError('Failed to load blog posts.'); // Set error message if fetch fails
      } finally {
        setLoading(false); // Set loading to false once fetching is done
      }
    };
    getBlogPosts();
  }, []); // Empty dependency array to run once on component mount
  return (
     // Blog section with optional dark theme styling
    <section
      id="blog"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Journey
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            My Blog
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>

        {/* Blog Posts */}
        <div className="blog-list">
          <h1 className="text-center">Blog Entries</h1>
          {loading && <p>Loading blog posts...</p>}
          {error && <p>{error}</p>}
          {/* If posts are available, map over them to display each one */}
          {!loading && !error && posts.length > 0 ? (
            posts.map((post) => (
              <div key={post.id} className="blog-entry">
                <h3 className="blog-title">{post.title}</h3>
                <p className="blog-author-date">
                  <strong>{post.author}</strong> | Published on:&nbsp;
                  {new Date(post.createdAt).toLocaleDateString('en-GB')}
                </p>
                <p className="blog-content">{post.content}</p>
              </div>
            ))
          ) : (
            !loading && !error && <p className="no-posts-message">No blog posts available.</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default Blog;
