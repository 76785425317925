import React from "react";
import Typewriter from "typewriter-effect";

// Home component displaying the introductory section
const Home = ({ classicHeader, darkTheme, handleNavClick }) => {
  return (
    <section id="home">
      <div className="hero-wrap">
        <div className="hero-mask opacity-8 bg-dark" />

        {/* ---------------image background------------------ */}
        <div
          className="hero-bg parallax"
          style={{ backgroundImage: 'url("images/intro-bg.jpg")' }}
        ></div>

        {/* Content area for intro section */}
        <div className="hero-content section d-flex min-vh-100">
          <div className="container my-auto">
            <div className="row">
              <div className="col-12 text-center">

                {/* Welcome message */}
                <p className="text-7 fw-500 text-white mb-2 mb-md-3">Welcome</p>

                {/* Typewriter effect for name and profession */}
                <h2 className="text-16 fw-600 text-white mb-2 mb-md-3">
                  <Typewriter
                    options={{
                      strings: [
                        "I'm Ian Burke.",
                        "I'm a Software Engineer.",
                      ],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                  {/* Location information */}
                </h2>
                <p className="text-5 text-light mb-4">
                  based in Co. Galway, Ireland.
                </p>
                
              </div>
            </div>
          </div>
           {/* Scroll down arrow to About section */}
          <a
            href="#about"
            className="scroll-down-arrow text-white smooth-scroll"
            onClick={(e) => {
              e.preventDefault();
              handleNavClick("about");
            }}
          >
            {/* Animated arrow icon */}
            <span className="animated">
              <i className="fa fa-chevron-down" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Home;
