import "./App.scss"; // Importing the main stylesheet for the app.
import Header from "./components/Header";
import Home from "./components/Home";
import AboutUs from "./components/About";
import Projects from "./components/Projects";
import Blog from "./components/Blog";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import { useEffect, useState } from "react";
import ClassicHeader from "./components/ClassicHeader";
import PreLoader from "./components/Preloader";
import { Tooltip } from "./components/Tooltip";

function App() {
  const classicHeader = false; // Boolean to decide whether to use the classic header or not.
  const darkTheme = false; // Boolean to decide if the dark theme should be applied.

  // Function to handle navigation click and scroll smoothly to the specified section.
  const handleNavClick = (section) => {
    document.getElementById(section).scrollIntoView({ behavior: "smooth" });
  };

  const [scrollTopVisible, setScrollTopVisible] = useState(false); // State to control the visibility of the "back to top" button.
  const [isLoading, setisLoading] = useState(true); // State to control the preloader visibility.

  // useEffect to handle the loading state and display a preloader for 1 second.
  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setisLoading(false);
    }, 1000);
    return () => {
      clearTimeout(loadingTimeout); // Clear the timeout when the component unmounts.
    };
  }, []);

  // Function to check the scroll position and set the visibility of the "back to top" button accordingly.
  const checkScrollTop = () => {
    let scrollTopBtn = document.getElementById("back-to-top");

    if (scrollTopBtn) {
      if (
        document.body.scrollTop > 400 || 
        document.documentElement.scrollTop > 400
      ) {
        setScrollTopVisible(true); // Show "back to top" button when scrolled 400px down.
      } else {
        setScrollTopVisible(false); // Hide "back to top" button if scrolled above 400px.
      }
    }
  };

  // Add an event listener to check the scroll position and update button visibility.
  if (typeof window !== "undefined") {
    window.addEventListener("scroll", checkScrollTop);
  }

  return (
    <>
      <div
        style={{ position: "relative" }}
        className={classicHeader ? "" : "side-header"} // Apply "side-header" class if classicHeader is false.
      >
        {isLoading && <PreLoader></PreLoader>} {/* Display the preloader if isLoading is true */}

        <div id="main-wrapper">
          {/* Conditionally render either ClassicHeader or Header based on the classicHeader flag */}
          {classicHeader ? (
            <ClassicHeader handleNavClick={handleNavClick}></ClassicHeader>
          ) : (
            <Header handleNavClick={handleNavClick}></Header>
          )}

          <div id="content" role="main">
            {/* Render main content components */}
            <Home
              classicHeader={classicHeader}
              darkTheme={darkTheme}
              handleNavClick={handleNavClick}
            ></Home>
            <AboutUs
              classicHeader={classicHeader}
              darkTheme={darkTheme}
            ></AboutUs>
            <Projects
              classicHeader={classicHeader}
              darkTheme={darkTheme}
            ></Projects>
            <Blog
              classicHeader={classicHeader}
              darkTheme={darkTheme}
            ></Blog>
            <Contact
              classicHeader={classicHeader}
              darkTheme={darkTheme}
            ></Contact>
          </div>
          {/* Render footer */}
          <Footer
            classicHeader={classicHeader}
            darkTheme={darkTheme}
            handleNavClick={handleNavClick}
          ></Footer>
        </div>

        {/* Back to top button */}
        <Tooltip text="Back to Top" placement="left">
          <span
            id="back-to-top"
            className="rounded-circle"
            style={{ display: scrollTopVisible ? "inline" : "none" }} // Display button if scrollTopVisible is true.
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll smoothly to the top of the page.
            }}
          >
            <i className="fa fa-chevron-up"></i> {/* Icon for the "back to top" button */}
          </span>
        </Tooltip>
      </div>
    </>
  );
}

export default App;
