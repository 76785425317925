import axios from 'axios'; // Importing Axios library to handle HTTP requests.

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
console.log(BASE_URL)
export const fetchAboutContent = async () => {
  try {
    // Making a GET request to the specified API endpoint to retrieve about me content.
    const response = await axios.get(`${BASE_URL}/about`);
    // Returning the data received from the API, assuming it contains an array of the content
    return response.data;
  } catch (error) {
    // Logging an error message to the console if the API request fails.
    throw new Error('Failed to fetch about content: ' + error.message);
  }
};
