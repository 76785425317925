// Import the Tooltip functionality from Bootstrap library and React hooks
import { Tooltip as BsTooltip } from "bootstrap";
import React, { useEffect, useRef } from "react";

// Custom Tooltip component to wrap around child components and provide Bootstrap tooltip functionality
export const Tooltip = (p) => {
  // Create a reference to the child element that will have the tooltip attached
  const childRef = useRef(undefined);

  // Use the useEffect hook to initialize the tooltip when the component mounts and clean it up when it unmounts
  useEffect(() => {
    // Create a new Bootstrap tooltip instance on the child element, using props for text, placement, and trigger
    const t = new BsTooltip(childRef.current, {
      title: p.text, // The text displayed in the tooltip
      placement: p.placement, // The position of the tooltip (e.g., 'top', 'bottom', 'left', 'right')
      trigger: "hover", // Tooltip will show on hover
    });

    // Cleanup function to dispose of the tooltip when the component is unmounted to avoid memory leaks
    return () => t.dispose();
  }, [p.text, p.placement]); // Dependencies - re-run effect if text or placement changes

  // Clone the child element passed as props and attach the reference to it
  return React.cloneElement(p.children, { ref: childRef });
};
