import React from "react";

// Footer component displayed at the bottom of the page
// Props:
// - classicHeader: Boolean, determines if classic header style is used to adjust padding
// - darkTheme: Boolean, applies dark styling to the footer if true
// - handleNavClick: Function, handles navigation clicks within the app
const Footer = ({ classicHeader, darkTheme, handleNavClick }) => {
  return (
    // Footer section with optional dark theme styling
    <footer
      id="footer"
      className={"section " + (darkTheme ? "footer-dark bg-dark-1" : "")}
    >
      {/* Container with optional padding based on header type */}
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        <div className="row">
          <div className="col-12 text-center">
            {/* Copyright text */}
            <p className="mb-3 mb-lg-0">
              Copyright © 2024{" "}
              {/* Link to navigate to home section */}
              <a
                href="#home"
                className="fw-500"
                onClick={(e) => {
                  e.preventDefault();
                  handleNavClick("home");
                }}
              >
                Ian Burke
              </a>
              . All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
